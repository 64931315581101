const rollbarConfig = {
  accessToken: "",
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: "production",
    client: {
      javascript: {
        source_map_enabled: true,
      },
    },
  },
};

export default rollbarConfig;
